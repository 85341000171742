/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
/* eslint-disable-next-line linebreak-style */

export const environments = {
  apiStripeUrl: 'https://j1ay5c9qrd.execute-api.us-east-1.amazonaws.com/pay',
  AWS_REGION: 'us-east-1',
  AWS_API_GATEWAY: 'https://n5um5iws84.execute-api.us-east-2.amazonaws.com',
  DSIM_KEY: '',
  DSIM_SECRET: '',
  apiDrSimTools: 'https://api-desbloqueamicell-production.up.railway.app/drsim/tools',
  apiDrSimCreateOrden: 'https://nnrka8dc80.execute-api.us-east-1.amazonaws.com/drsim/create_order',
  apiGatewayOrdenTicket: 'https://0v8aexvf86.execute-api.us-east-1.amazonaws.com/tickets/ordenes',
  apiBackendAzure: 'https://api-desbloqueamicell-production.up.railway.app/',
  apiBackendAzurePaises: 'https://api-desbloqueamicell-production.up.railway.app/paises',
  apiBackendAzureOperadoras: 'https://api-desbloqueamicell-production.up.railway.app/operadoras',
  apiBackendAzureMarcas: 'https://api-desbloqueamicell-production.up.railway.app/marcas',
  apiBackendAzureDispositivos: 'https://api-desbloqueamicell-production.up.railway.app/modelos',
};
